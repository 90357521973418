import {React,useEffect, useState} from 'react';
import Select from 'react-select';
import {CallApi} from "../common/API/APi";
import {success,error} from "../common/Toastify"; 

const AssignDriver = (props) => {
   let {assigndriverid,setNotifyrole}=props;
   const [assignoption,setAssignoption]=useState([]);
   const [returnassignoption,setReturnAssignoption]=useState([]);
   const [vehicelmodeldata, setVehicelmodeldat] = useState([]);
   const [assignid,setAssignid] =useState(assigndriverid);
   const [errors, setError] = useState();

    const getvehicledata=async()=>{
        let res = await CallApi({}, "getvehiclemodel");
        if(res?.status === 200){
         
          let array=[];
          res?.data.map((value)=>{
            array.push({label:value?.name,value:value?.id})      
          })
          setVehicelmodeldat(array)
        }
      }
  

   const changemodel =async(e,status)=>{
    setAssignoption([]);
    if(status){
      setAssignid({...assignid,assignmodelid:e,driver_id:assigndriverid?.driver_id,assignamount:assigndriverid?.assignamount,return_drivervehicle_model:assigndriverid?.return_drivervehicle_model,return_driverid:assigndriverid?.return_driverid,return_driver_amount:assigndriverid?.return_driver_amount});
    }else{
      setAssignid({...assignid,assignmodelid:e});
    }
 
      let res = await CallApi({'model_id':e.value},'getdriverbymodel');
      if(res?.status === 200){
        if(res?.data?.length){
          let array=[];
          res?.data.map((value)=>{
            array.push({'label':value?.name,'value':value?._id});
          })
          setAssignoption(array)
        

        }
      }
    }
    const returnchangemodel =async(e,status)=>{
      setAssignoption([]);
      if(status){
        setAssignid({...assignid,assignmodelid:assigndriverid?.assignmodelid,driver_id:assigndriverid?.driver_id,assignamount:assigndriverid?.assignamount,return_drivervehicle_model:e,return_driverid:assigndriverid?.return_driverid,return_driver_amount:assigndriverid?.return_driver_amount});
      }else{
        setAssignid({...assignid,return_drivervehicle_model:e});
      }
   
        let res = await CallApi({'model_id':e.value},'getdriverbymodel');
        if(res?.status === 200){
          if(res?.data?.length){
            let array=[];
            res?.data.map((value)=>{
              array.push({'label':value?.name,'value':value?._id});
            })
            setReturnAssignoption(array)
          
  
          }
        }
      }
    
    const assigntoorder =async()=>{
    
     if(!assignid?.assignmodelid?.value){
          setError({ assignmodelid: true });
          return;
      } 
      if(!assignid?.driver_id?.value){
        setError({ driver_id: true });
        return;
    } 
      if(!assignid?.assignamount){
        setError({ assignamount: true });
        return;
    } 
    if(assigndriverid?.bookreturn == 1){
      if(!assignid?.return_drivervehicle_model?.value){
        setError({ return_drivervehicle_model: true });
        return;
      } 
      if(!assignid?.return_driverid?.value){
        setError({ return_driverid: true });
        return;
      } 
      if(!assignid?.return_driver_amount){
        setError({ return_driver_amount: true });
      
      }
      assignid['return_driver_vehicle_model'] = assignid?.return_drivervehicle_model?.value;
      delete assignid?.return_drivervehicle_model;
      assignid['return_driver_id'] = assignid?.return_driverid?.value;
      delete assignid?.return_driverid;
    }

    assignid['order_id']=assigndriverid?.order_id;
    assignid['vehicle_model'] = assignid?.assignmodelid?.value;
   
      delete assignid?.assignmodelid;

      assignid['driverid'] = assignid?.driver_id?.value;
      delete assignid?.driver_id;
     
      let res = await CallApi(assignid,'assign_driver');
      if(res?.status === 200){
        setNotifyrole(current=>!current);
        setAssignid('r');
        success(res?.message);
        document.querySelector(".assigncancel").click();
      }else{
        error(res?.message);
      }
    
    }

    useEffect(()=>{
      if(assigndriverid?.order_id || assigndriverid?.order_id !=undefined){
        getvehicledata();
        setAssignid(assigndriverid);
        setError('');
      }
    },[assigndriverid?.order_id])

    useEffect(()=>{
    
           setTimeout(()=>{
            if(assigndriverid?.assignmodelid?.value){
              changemodel(assigndriverid?.assignmodelid,true);
            }
            if(assigndriverid?.bookreturn == 1 && assigndriverid?.return_drivervehicle_model?.value){
              returnchangemodel(assigndriverid?.return_drivervehicle_model,true);
            }

           },2000)
    },[assigndriverid?.order_id])

  return(
    <div className="modal fade com-modal" id="assigndrivermodal" tabIndex="-1" aria-labelledby="assigndrivermodalLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5 golden-text" id="assigndrivermodalLabel">Assign Driver</h1>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body pb-0">

          <div className='row my-4'>
              <div className='col-md-12 mb-4'>
              <Select
                  value={assignid?.assignmodelid}
                  options={vehicelmodeldata}
                  onChange={(e)=>{changemodel(e,false)}}
                  classNamePrefix={errors?.assignmodelid? " com-select border-danger" : "com-select"}
                  isSearchable= {false}
                  placeholder="Vehicle Model"
                />
              </div>
            </div>
            
            <div className='row my-4'>
              <div className='col-md-12 mb-4'>
              <Select
                  value={assignid?.driver_id}
                  options={assignoption}
                  onChange={(e)=>setAssignid({...assignid,driver_id:e})}
                  classNamePrefix={errors?.driver_id? " com-select border-danger" : "com-select"}
                  isSearchable= {false}
                  placeholder="Assign Driver"
                />
              </div>
            </div>
            <div className='row my-4'>
              <div className='col-md-12 mb-4'>
                <input type="number" placeholder='Driver Amount For Ride(in $)' className={errors?.assignamount? " com-input-field border-danger" : "com-input-field"}  value={assignid?.assignamount} onChange={(e)=>setAssignid({...assignid,'assignamount':e.target.value})}/>
              </div>
            </div>
          {assigndriverid?.bookreturn == 1?<>           
            <div className='row my-4'>
              <div className='col-md-12 mb-4'>
              <Select
                  value={assignid?.return_drivervehicle_model}
                  options={vehicelmodeldata}
                  onChange={(e)=>{returnchangemodel(e,false)}}
                  classNamePrefix={errors?.return_drivervehicle_model? " com-select border-danger" : "com-select"}
                  isSearchable= {false}
                  placeholder="Return Driver Vehicle Model"
                />
              </div>
            </div>

            <div className='row my-4'>
              <div className='col-md-12 mb-4'>
              <Select
                  value={assignid?.return_driverid}
                  options={returnassignoption}
                  onChange={(e)=>setAssignid({...assignid,return_driverid:e})}
                  classNamePrefix={errors?.return_driverid? " com-select border-danger" : "com-select"}
                  isSearchable= {false}
                  placeholder="Assign Driver For Return"
                />
              </div>
            </div>

            <div className='row my-4'>
              <div className='col-md-12 mb-4'>
                <input type="number" placeholder='Return Trip Driver Amount For Ride(in $)' className={errors?.return_driver_amount? " com-input-field border-danger" : "com-input-field"}  value={assignid?.return_driver_amount} onChange={(e)=>setAssignid({...assignid,'return_driver_amount':e.target.value})}/>
              </div>
            </div>
            </>


        :''}
          </div>
          <div className="modal-footer text-center">
            <button type="button" className="golden-bg font-13px com-btn golden-border" onClick={assigntoorder} >Assign</button>
            <button type="button" className="golden-bg font-13px com-btn com-btn-outline golden-border bg-transparent assigncancel" data-bs-dismiss="modal">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  )
} 

export default AssignDriver;
